import { useFormikContext } from 'formik'

import { Box, Typography } from '@mui/material'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import Emoji from 'components/UI/Emoji'
import CellphoneField from 'components/UI/Formik/CommonFields/CellphoneField'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import IdentificationNumberField from 'components/UI/Formik/CommonFields/IdentificationNumberField'
import FormField from 'components/UI/Formik/FormField/Index'

import { chronometer } from 'utils/emojis'
import { closedQuestionOptions } from 'utils/worker'

import messages from 'messages/worker'

const BasicFormFields = () => {
  const { values } = useFormikContext()
  const { showPremiumFeatures, showHRFeatures } = usePremiumFeature()

  return (
    <Box>
      <Typography
        variant="h6"
        color="primary.dark"
        sx={(theme) => ({
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(2),
        })}
      >
        Datos básicos
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          width: '100%',
          gridTemplateColumns: '1fr',
          marginTop: theme.spacing(2),
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            rowGap: theme.spacing(2),
            columnGap: theme.spacing(3),
            [theme.breakpoints.up('tablet')]: {
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
            },
          })}
        >
          <FormField name="name" label="Nombres" optional={false} />
          <FormField name="last_name" label="Apellidos" optional={false} />
          <DocumentTypeField optional={false} />
          <IdentificationNumberField optional={false} />
          <FormField
            name="active_user"
            label="¿Habilitar acceso web a esta persona?"
            variant="radio-group"
            tooltipContent={
              <>
                {messages.allow_web_access({
                  showPremiumFeatures,
                  showHRFeatures,
                })}
                {showPremiumFeatures || showHRFeatures ? (
                  <>
                    {' '}
                    <Emoji code={chronometer} />
                  </>
                ) : null}
              </>
            }
            row
            options={closedQuestionOptions}
          />
          <FormField
            name="email"
            type="email"
            label="Correo electrónico"
            optional={values.active_user === false}
          />
          <CellphoneField name="phone" label="Celular" placeholder=" " />
        </Box>
      </Box>
    </Box>
  )
}

export default BasicFormFields
