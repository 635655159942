import { useLocation } from 'react-router-dom'
import * as yup from 'yup'

import { Box } from '@mui/material'
import { Button, Chip, Typography, alpha } from '@mui/material'

import { useSupportMenuContext } from 'components/App/SupportMenuContext'
import Icon from 'components/UI/Icon'
import Tooltip from 'components/UI/Tooltip'

import { isOrganizer } from 'utils/auth'
import {
  subscriptionPaymentCategories,
  subscriptionPaymentStatus,
} from 'utils/company'
import { formatDisplayDateString } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'
import { formatCurrency } from 'utils/format'

import { getCurrentPaymentMethods } from '../Index/PaymentMethod/helpers'
import usePaymentModal from './PaymentModal/usePaymentModal'
import useUpdatePaymentMethodInfoModal from './UpdatePaymentMethodInfoModal/useUpdatePaymentMethodInfoModal'

yup.setLocale(yupLocaleES)

const statusColor = {
  approved: 'success',
  pending: 'warning',
  quote: 'info',
  rejected: 'error',
  disconnected: 'primary',
}

const getStatusBackgroundColor = (theme, status) => {
  const currentStatusColor = {
    approved: theme.palette.success.light,
    pending: theme.palette.warning.light,
    quote: theme.palette.info.light,
    rejected: theme.palette.error.light,
    disconnected: theme.palette.primary.dark,
  }

  return currentStatusColor[status]
}

export const PaymentStatus = ({ statusInfo = {} }) => {
  const { status, rejected_comment: rejectedComment } = statusInfo
  const { pathname } = useLocation()
  const isInPaymentHistoryView = pathname === '/subscription_detail/payments'
  const paymentModal = usePaymentModal()
  const { openSupportMenuFromOutside } = useSupportMenuContext()
  const { openUpdatePaymentMethodInfoModal } = useUpdatePaymentMethodInfoModal()

  const openPaymentModal = () =>
    paymentModal.openModal({
      isEditingPaymentMethod: true,
      openUpdatePaymentMethodInfoModal,
    })

  return (
    <Tooltip
      title={`Estado ${isInPaymentHistoryView ? 'del pago' : 'de la cuenta'}: ${subscriptionPaymentStatus[status || 'pending']}`}
      description={
        <Typography
          sx={(theme) => ({
            marginTop: theme.spacing(1),
          })}
        >
          {rejectedComment ? <b>{`${rejectedComment}${' '}`}</b> : null}Si
          quieres revisar o actualizar tu método de pago, puedes hacerlo{' '}
          <Button
            onClick={openPaymentModal}
            sx={(theme) => ({
              width: 'fit-content',
              minWidth: 'fit-content',
              height: 'fit-content',
              padding: theme.spacing(0),
              borderRadius: 'none',
              backgroundColor: 'transparent',
              color: theme.palette.complementary1.dark,
              boxShadow: 'none',
              '&:hover, &:focus': {
                color: theme.palette.complementary1.light,
                backgroundColor: 'transparent',
                transform: 'none',
                boxShadow: 'none',
              },
            })}
          >
            aquí.
          </Button>{' '}
          Si necesitas más información, no dudes en contactar al equipo de{' '}
          <Button
            onClick={openSupportMenuFromOutside}
            sx={(theme) => ({
              width: 'fit-content',
              minWidth: 'fit-content',
              height: 'fit-content',
              padding: theme.spacing(0),
              borderRadius: 'none',
              backgroundColor: 'transparent',
              color: theme.palette.complementary1.dark,
              boxShadow: 'none',
              '&:hover, &:focus': {
                color: theme.palette.complementary1.light,
                backgroundColor: 'transparent',
                transform: 'none',
                boxShadow: 'none',
              },
            })}
          >
            Soporte
          </Button>
          .
        </Typography>
      }
      disableHoverListener={status === 'approved' || status === 'quote'}
    >
      <Chip
        label={
          <Typography
            variant="body2"
            fontWeight={700}
            sx={(theme) => ({
              color: `${theme.palette[statusColor[status || 'pending']]?.dark} !important`,
              padding: theme.spacing(0, 0.5),
            })}
          >
            {subscriptionPaymentStatus[status || 'pending']}
          </Typography>
        }
        sx={(theme) => ({
          backgroundColor: alpha(
            getStatusBackgroundColor(theme, status || 'pending'),
            0.2
          ),
        })}
      />
    </Tooltip>
  )
}

export const getColumnsData = (onClickAction, isActionDisabled) => {
  return [
    {
      Header: 'Fecha',
      accessor: 'date',
      Cell: ({ row }) => formatDisplayDateString(row.original.date),
      customWidth: '8rem',
    },
    {
      Header: 'Plan',
      accessor: 'plan',
      Cell: ({ row }) => {
        const plan =
          row.original.plan?.length > 24
            ? `${row.original.plan.slice(0, 24)}...)`
            : row.original.plan

        return row.original.plan?.length > 24 ? (
          <Tooltip description={row.original.plan}>
            <span>{plan}</span>
          </Tooltip>
        ) : (
          plan
        )
      },
      customWidth: '9rem',
    },
    {
      Header: 'Personas',
      accessor: 'paid_workers',
      emptyValue: 0,
      customWidth: '7rem',
    },
    {
      Header: 'Monto',
      accessor: 'value',
      Cell: ({ row }) => formatCurrency(row.original.value),
      customWidth: '9rem',
    },
    {
      Header: 'Método de pago',
      accessor: 'category',
      Cell: ({ row }) =>
        subscriptionPaymentCategories[row.original.category]
          ? subscriptionPaymentCategories[row.original.category]
          : row.original.category,
      customWidth: '14rem',
    },
    {
      Header: 'Estado',
      accessor: 'status',
      Cell: ({ row }) => <PaymentStatus statusInfo={row.original} />,
      customWidth: '11rem',
      alignHeader: 'center',
    },
    {
      Header: 'Factura',
      accessor: 'invoice',
      key: 'invoice',
      Cell: ({ row }) => {
        let billOptions = '-'

        if (row.original.invoice)
          billOptions = (
            <Button
              onClick={() => onClickAction(row.original.id, 're-send')}
              variant="text"
              startIcon={<Icon name="send" />}
            >
              Reenviar
            </Button>
          )
        else if (
          isOrganizer() &&
          !isActionDisabled &&
          parseInt(row.id, 10) === 0 &&
          row.original.status === 'approved'
        )
          billOptions = (
            <Button
              variant="text"
              onClick={() => onClickAction(row.original.id, 're-invoice')}
              startIcon={<Icon name="refresh-cw" />}
            >
              Refacturar
            </Button>
          )

        return billOptions
      },
      alignHeader: 'center',
      alignCell: 'center',
      customWidth: '8rem',
    },
  ]
}

const formData = {
  invoice_id: {
    fieldName: 'invoice_id',
    title: 'ID de la factura',
    schemaValidation: yup.object({
      invoice_id: yup
        .number('Debes ingresar un número de factura válido')
        .positive()
        .nullable()
        .required(),
    }),
  },
  paid_extra_workers: {
    fieldName: 'paid_extra_workers',
    title: 'trabajadores extras pagados',
    schemaValidation: yup.object({
      paid_extra_workers: yup.number().nullable().required(),
    }),
  },
}

export const getTableActions = (
  openCurrentPaymentModal,
  deleteSubscriptionPayment,
  handleRetryPayment,
  showPaymentRetryAction,
  paymentCategory,
  confirm
) => {
  if (!isOrganizer()) return []

  const paymentRetryOption =
    paymentCategory === 'credit_card'
      ? 'tarjeta débito/crédito'
      : 'débito automático'

  return [
    {
      id: 'paymentRetry',
      tooltip: `Reintentar cobro por ${paymentRetryOption}`,
      isFreeAction: true,
      onClick: () => {
        handleRetryPayment()
      },
      hidden: !showPaymentRetryAction,
    },
    (rowData) => ({
      id: 'editId',
      icon: <Icon name="file-pen-line" />,
      tooltip: 'Editar ID de la factura',
      onClick: () => openCurrentPaymentModal(rowData, formData.invoice_id),
    }),
    (rowData) => ({
      id: 'editWorkers',
      icon: <Icon name="user-plus" />,
      tooltip: 'Editar trabajadores extras pagados',
      disabled: rowData.rowIndex !== 0,
      onClick: () =>
        openCurrentPaymentModal(rowData, formData.paid_extra_workers),
    }),
    {
      id: 'deletePayment',
      icon: <Icon name="trash-2" />,
      tooltip: 'Eliminar pago',
      hidden: !isOrganizer(),
      onClick: (rowData) => {
        confirm({
          type: 'warning',
          title: 'Eliminar pago',
          description:
            'Eliminar este pago es permanente y no se podrá deshacer. ¿Estás seguro?',
          okText: 'Eliminar',
          confirmCheckbox: true,
          onOk: () => deleteSubscriptionPayment(rowData.id),
        })
      },
    },
  ]
}

export const getTabsData = ({
  subscription,
  isEditingPaymentMethod,
  currentPlanPayment,
  newSubscriptionType,
  isFromExtraWorkersModal,
}) => {
  const {
    payment_methods: availablePaymentMethods,
    subscription_payment_methods_info: currentPaymentMethodsInfo,
  } = subscription

  const { automatic_debit: automaticDebitMethodAvailable, pse: showPseMethod } =
    availablePaymentMethods || {}

  const currentPaymentMethods = getCurrentPaymentMethods(
    currentPaymentMethodsInfo
  )

  // In case of immediate payment required, shows automatic debit only when the bank account is already connected or is a current plan payment
  const showAutomaticDebitMethod =
    (currentPaymentMethods?.includes('automatic_debit') ||
      isEditingPaymentMethod ||
      currentPlanPayment) &&
    automaticDebitMethodAvailable

  const tabs = [
    {
      key: 'credit_card',
      label: (
        <Box display="flex" alignItems="center">
          <Icon
            name="credit-card"
            sx={(theme) => ({
              marginRight: theme.spacing(0.5),
            })}
          />
          Tarjeta débito/crédito
        </Box>
      ),
    },
  ]

  if (showAutomaticDebitMethod)
    tabs.push({
      key: 'automatic_debit',
      label: (
        <Box display="flex" alignItems="center">
          <Icon
            name="refresh-cw"
            sx={(theme) => ({
              marginRight: theme.spacing(0.5),
            })}
          />
          Débito automático
        </Box>
      ),
    })

  // Hide pse when editing current payment method
  // Show always for annual subscriptions or extra workers payments
  if (
    (showPseMethod ||
      newSubscriptionType === 'year' ||
      isFromExtraWorkersModal) &&
    !isEditingPaymentMethod
  )
    tabs.push({
      key: 'pse',
      label: (
        <Box display="flex" alignItems="center">
          <Icon
            name="hand-coins"
            sx={(theme) => ({
              marginRight: theme.spacing(0.5),
            })}
          />
          PSE
        </Box>
      ),
    })

  return tabs
}
