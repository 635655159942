import { Form, Formik } from 'formik'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Box, ButtonBase, Typography, useMediaQuery } from '@mui/material'

import Button from 'components/UI/Button/Button'
import Emoji from 'components/UI/Emoji'
import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'
import { Image } from 'components/UI/Image'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { heartOnFire } from 'utils/emojis'
import useUsersService from 'utils/hooks/settings/usersService'
import useConfetti from 'utils/hooks/useConfetti'
import useNotifications from 'utils/hooks/useNotifications'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import { PERIOD_GENERATED_PAYROLL } from 'config/routes'

import { initialValues, userOptions, validationSchema } from './helpers'

export function OnboardingIntroCompletedModalContent({ handleClose }) {
  const { pathname } = useLocation()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))
  const { throwConfetti } = useConfetti()
  const { showSuccessMessage } = useNotifications()

  const { usersMutation } = useUsersService({
    queryOptions: {
      enabled: false,
    },
  })

  useEffect(() => {
    if (pathname !== PERIOD_GENERATED_PAYROLL()) {
      throwConfetti({
        origin: { x: 0.5, y: 0.9 },
        particleCount: 150,
      })
      throwConfetti({
        origin: { x: 0.3, y: 0.4 },
        particleCount: 150,
      })
      throwConfetti({
        origin: { x: 0.7, y: 0.4 },
        particleCount: 150,
      })
    }
  }, [pathname, throwConfetti])

  const handleSubmit = (values) => {
    usersMutation.mutate(
      {
        mutationMethod: 'POST',
        mutationKey: 'createUser',
        user: {
          name: values.name,
          email: values.email,
          phone: values.phone,
          role: values.role,
          active: values.active,
        },
      },
      {
        onSuccess: () => {
          showSuccessMessage('El nuevo usuario ha sido invitado.')

          if (values.role === 'admin') {
            Mixpanel.track(MIXPANEL_EVENTS.ADDED_ADMIN, {
              user_id: getUserId(),
              user_role: getUserRole(),
              company_id: getCompanyId(),
            })
          }

          if (values.role === 'accountant') {
            Mixpanel.track(MIXPANEL_EVENTS.ADDED_ACCOUNTANT, {
              user_id: getUserId(),
              user_role: getUserRole(),
              company_id: getCompanyId(),
            })
          }

          handleClose()
        },
      }
    )
  }

  return (
    <Box
      sx={{
        height: '100%',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: {
          mobile: '1fr',
          tablet: '18.063rem 1fr',
        },
      }}
    >
      <ButtonBase
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          cursor: 'pointer',
          color: theme.palette.black.main,
          right: theme.spacing(-1),
          top: theme.spacing(0),
        })}
      >
        <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
      </ButtonBase>
      {!isMobile ? (
        <>
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.warning[400],
              width: '50.313rem',
              height: '50.313rem',
              borderRadius: '50%',
              position: 'absolute',
              left: '-35rem',
              top: '-10rem',
            })}
          />
          <Image
            src="c107b57d-93fe-4552-cea0-1e151e855b00"
            width={289}
            height={394}
            style={{
              zIndex: 1,
            }}
          />
        </>
      ) : null}
      <Box
        sx={(theme) => ({
          [theme.breakpoints.up('tablet')]: {
            marginRight: theme.spacing(2.5),
            marginLeft: theme.spacing(1),
          },
        })}
      >
        <Typography
          variant="h4"
          sx={(theme) => ({
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(4),
          })}
        >
          ¡Aleluya! Terminaste los primeros pasos <Emoji code={heartOnFire} />
        </Typography>
        <Typography
          color="black.dark"
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.spacing(3),
            gap: theme.spacing(1),
          })}
        >
          Ahora puedes invitar a otro admin o contador para que puedan sacarle
          todo el jugo a tu cuenta.
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(2),
              })}
            >
              <FormField name="name" label="Nombre" optional={false} />
              <FormField
                name="email"
                type="email"
                label="Correo"
                optional={false}
              />
              <FormField
                name="role"
                variant="chip-group"
                optional={false}
                label="¿Qué Rol cumplirá?"
                options={userOptions}
              />
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  gap: theme.spacing(2),
                  marginTop: theme.spacing(3),
                  [theme.breakpoints.down('tablet')]: {
                    flexDirection: 'column',
                  },
                })}
              >
                <Button type="submit" loading={usersMutation.isLoading}>
                  Invitar usuario
                </Button>
                <Button onClick={handleClose} variant="outlined">
                  Cerrar
                </Button>
              </Box>
            </Box>
          </Form>
        </Formik>
      </Box>
    </Box>
  )
}
