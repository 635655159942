import { useNavigate } from 'react-router-dom'

import {
  Box,
  Button,
  ButtonBase,
  Typography,
  useMediaQuery,
} from '@mui/material'

import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'
import { Image } from 'components/UI/Image'

import { fire } from 'utils/emojis'

import { ONBOARDING_INTRO } from 'config/routes'

export function FirstPayrollModal({ handleClose }) {
  const isLaptopUp = useMediaQuery((theme) => theme.breakpoints.up('laptop'))
  const navigate = useNavigate()
  function closeModalWithRedirect() {
    navigate(ONBOARDING_INTRO())
    handleClose()
  }
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
      }}
    >
      <ButtonBase
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          cursor: 'pointer',
          color: theme.palette.black.main,
          right: theme.spacing(-1),
          top: theme.spacing(0),
        })}
      >
        <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
      </ButtonBase>
      <Box
        sx={{
          display: 'grid',
          overflow: 'hidden',
          gridTemplateColumns: {
            mobile: '1fr',
            laptop: '1fr  3fr',
          },
        }}
      >
        {isLaptopUp ? (
          <Box>
            <Box
              sx={(theme) => ({
                width: '30rem',
                height: '30rem',
                borderRadius: '100%',
                position: 'absolute',
                left: '-11rem',
                top: '-5rem',
                backgroundColor: theme.palette.complementary2.main,
              })}
            />
            <Box
              sx={{
                position: 'relative',
                height: '100%',
                width: '18rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Image
                src="e08c1124-3af5-4f97-2fe0-07e6ebe11600"
                height={266}
                width={254}
              />
            </Box>
          </Box>
        ) : null}
        <Box
          sx={(theme) => ({
            marginRight: theme.spacing(2.5),
            [theme.breakpoints.up('laptop')]: {
              marginLeft: theme.spacing(4),
            },
          })}
        >
          <Typography
            variant="h2"
            sx={(theme) => ({
              marginBottom: theme.spacing(2),
              textWrap: 'balance',
              marginRight: theme.spacing(2),
            })}
          >
            ¡Aleluya! Liquidaste tu nómina correctamente{' '}
            <Emoji code={fire} sx={{ fontSize: '2rem' }} />
          </Typography>
          <Typography
            variant="body1"
            color="black.dark"
            sx={(theme) => ({
              marginBottom: theme.spacing(4),
              gap: theme.spacing(2),
            })}
          >
            ¡Felicitaciones!{' '}
            <Typography component="span" fontWeight="bold">
              Tu primera nómina
            </Typography>{' '}
            ha sido liquidada con éxito. Desde esta sección podrás revisar los
            cálculos, enviar colillas y mucho más. Ahora finaliza los{' '}
            <Typography component="span" fontWeight="bold">
              primeros pasos!
            </Typography>
          </Typography>
          <Button
            onClick={closeModalWithRedirect}
            sx={(theme) => ({
              padding: theme.spacing(0, 6),
              marginTop: theme.spacing(4),
              width: '100%',
              [theme.breakpoints.up('tablet')]: {
                width: 'auto',
              },
            })}
          >
            Continuar
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
