import { useQueryClient } from 'react-query'

import useModals from 'components/App/ModalsManager/useModals'

import { getCompanyId } from 'utils/company'

import { OnboardingIntroCompletedModalContent } from './IntroCompletedModalContent'

export function useOnboardingIntroCompletedModal() {
  const modals = useModals()
  const queryClient = useQueryClient()

  const handleClose = () => {
    modals.closeModal('introCompletedModal')
    queryClient.invalidateQueries(['companyInformation', getCompanyId()])
  }

  const openOnboardingIntroCompletedModal = () => {
    modals.openModal({
      id: 'onboardingIntroCompletedModal',
      content: (
        <OnboardingIntroCompletedModalContent handleClose={handleClose} />
      ),
      modalProps: {
        hideFooter: true,
        onCancel: handleClose,
        paperSx: {
          maxWidth: '49rem',
        },
        contentWrapperSx: {
          overflow: 'hidden',
        },
      },
    })
  }

  return { openOnboardingIntroCompletedModal }
}
