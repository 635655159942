import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Box } from '@mui/material'

import { usePremiumActions } from 'components/App/Premium/helpers'
import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import Page from 'components/UI/Page/Page'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import { useOnboardingMutation } from 'utils/hooks/auth/onboarding'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import {
  DASHBOARD,
  LOGOUT,
  ONBOARDING_AREA,
  ONBOARDING_COMPANY,
  ONBOARDING_GOAL,
  ONBOARDING_INTRO,
} from 'config/routes'

import AuthLogo from '../common/AuthLogo'
import { OnboardingStepper } from './Stepper'
import {
  getCurrentOnboardingStepData,
  getInitialValues,
  latestCompletedStepPathname,
  stepsData,
  validationSchema,
} from './helpers'

export function Onboarding() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const { company } = useUser()
  const [companyValues, setCompanyValues] = useState(company)
  const { handleActivatePremiumTrial } = usePremiumActions()
  const { handleUpdateOnboarding, isLoading } = useOnboardingMutation()

  const {
    currentStepIndex,
    currentStepPathname,
    currentStepTitle,
    latestCompletedStepIndex,
  } = getCurrentOnboardingStepData({
    pathname: location?.pathname,
    latestCompletedStep: companyValues?.onboarding?.onboarding_step,
  })

  const initialValues = getInitialValues(companyValues)[currentStepPathname]
  const currentValidationSchema = validationSchema[currentStepPathname]

  const handleChangeStep = (stepIndex) => {
    const navigationStep =
      stepIndex === 0
        ? ONBOARDING_COMPANY()
        : stepIndex === 1
          ? ONBOARDING_AREA()
          : null

    navigate(navigationStep, { replace: true })
  }

  const handleNextStep = () => {
    const navigationStep =
      currentStepPathname === 'company'
        ? ONBOARDING_AREA()
        : currentStepPathname === 'area'
          ? ONBOARDING_GOAL()
          : ONBOARDING_INTRO()

    if (currentStepPathname === 'goal') {
      localStorage.setItem('one-by-session-redirected-to-intro', true)
    }

    navigate(
      navigationStep,
      currentStepPathname === 'goal'
        ? { state: { showWelcomeModal: true }, replace: true }
        : undefined
    )
  }

  const handleSubmit = async (values) => {
    const valuesToSend = getDirtyValues(
      initialValues,
      values,
      currentValidationSchema.fields
    )

    if (!isObjectEmpty(valuesToSend)) {
      const followingOnboardingStep =
        currentStepPathname === 'company'
          ? 'user_area'
          : currentStepPathname === 'area'
            ? 'main_goal'
            : 'finished'

      handleUpdateOnboarding({
        data: {
          ...valuesToSend,
          onboarding_step:
            currentStepIndex >= latestCompletedStepIndex
              ? followingOnboardingStep
              : undefined,
        },
        callback: async (newValues) => {
          if (currentStepPathname === 'goal') {
            handleActivatePremiumTrial()

            await queryClient.invalidateQueries([
              'companyInformation',
              getCompanyId(),
            ])

            Mixpanel.track(MIXPANEL_EVENTS.SIGNUP_COMPLETED, {
              user_id: getUserId(),
              company_id: getCompanyId(),
              user_role: getUserRole(),
              main_goal: valuesToSend.main_goal,
              main_goal_other_info: valuesToSend.main_goal_other_info,
            })
          }

          if (currentStepPathname === 'company') {
            Mixpanel.track(MIXPANEL_EVENTS.SIGNUP_COMPANY, {
              user_id: getUserId(),
              company_id: getCompanyId(),
              user_role: getUserRole(),
            })
          }

          if (currentStepPathname === 'area') {
            Mixpanel.track(MIXPANEL_EVENTS.SIGNUP_AREA, {
              user_id: getUserId(),
              company_id: getCompanyId(),
              user_role: getUserRole(),
              user_area: valuesToSend.user_area,
              user_area_other_info: valuesToSend.user_area_other_info,
            })
          }

          setCompanyValues(newValues)
          handleNextStep()
        },
      })
    } else {
      handleNextStep()
    }
  }

  // Avoids navigating to an especific step without completing the previous ones
  if (latestCompletedStepIndex < currentStepIndex) {
    return (
      <Navigate
        to={
          latestCompletedStepPathname[companyValues.onboarding.onboarding_step]
        }
      />
    )
  }

  // Avoids navigation to onboarding routes after completing it
  if (company?.onboarding?.onboarding_step === 'finished') {
    return (
      <Navigate
        to={
          company?.onboarding_first_steps?.finished_all_steps
            ? DASHBOARD
            : ONBOARDING_INTRO()
        }
      />
    )
  }

  return (
    <Page
      grid
      documentTitle={currentStepTitle || ''}
      rootSx={({ palette }) => ({
        backgroundColor: palette.white.main,
        gridColumn: '1 / -1',
      })}
      contentSx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        gridColumn: '1 / -1',
      }}
    >
      <Box
        sx={({ breakpoints }) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '22rem',
          [breakpoints.down('tablet')]: {
            width: '20rem',
          },
        })}
      >
        <AuthLogo />
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            margin: theme.spacing(4, 0, 3, 0),
          })}
        >
          <OnboardingStepper
            steps={stepsData}
            currentStep={currentStepIndex}
            onStepChange={handleChangeStep}
          />
        </Box>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={currentValidationSchema}
        >
          <Form
            style={{
              width: '100%',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Outlet context={{ userName: company?.onboarding?.user_name }} />
              <Button
                type="submit"
                loading={isLoading}
                sx={(theme) => ({ marginTop: theme.spacing(6), width: '100%' })}
              >
                {currentStepPathname === 'goal' ? 'Finalizar' : 'Siguiente'}
              </Button>
            </Box>
          </Form>
        </Formik>
        <Button
          sx={({ spacing }) => ({ marginTop: spacing(3) })}
          variant="text"
          onClick={() => navigate(LOGOUT, { replace: true })}
        >
          Salir del registro
        </Button>
      </Box>
    </Page>
  )
}
