/* 😢 */ export const cryEmoji = '\u{1F622}'
/* 🎅 */ export const fatherChristmas = '\u{1F385}'
/* 🙂 */ export const smilingEmoji = '\u{1F642}'
/* 🥳 */ export const partyingFace = '\u{1F973}'
/* 😍 */ export const faceHeartEyes = '\u{1F60D}'
/* 🤩 */ export const starEyesFace = '\u{1f929}'
/* 😬 */ export const grimacingFace = '\u{1f62c}'
/* 😀 */ export const happyFace = '\u{1f603}'
/* 😄 */ export const superHappyFace = '\u{1f604}'
/* 🤔 */ export const thinkingFace = '\u{1F914}'
/* 😎 */ export const sunglassesFace = '\u{1f60e}'
/* 😉 */ export const winkingFace = '\u{1F609}'
/* 😅 */ export const grinningFaceWithSweat = '\u{1F605}'
/* 🌞 */ export const sunFace = '\u{1F31E}'
/* 👀 */ export const eyes = '\u{1F440}'
/* ❤️ */ export const redHeart = '\u{FE0F}\u{2764}\u{FE0F}'
/* 💚 */ export const greenHeart = '\u{1f49a}'
/* ☕ */ export const coffee = '\u{2615}'
/* 🍦 */ export const softIceCream = '\u{1F366}'
/* 👋 */ export const wavingHand = '\u{1F44B}'
/* 👉 */ export const rightPointingHand = '\u{1f449}'
/* 💪 */ export const flexedBiceps = '\u{1F4AA}'
/* 🚀 */ export const rocket = '\u{1F680}'
/* 🏎️ */ export const raceCar = '\uD83C\uDFCE\uFE0F'
/* 🚗 */ export const car = '\u{1F697}'
/* 🏹 */ export const bowAndArrow = '\u{1F3F9}'
/* 🎉 */ export const partyPopperEmoji = '\u{1F389}'
/* 🔧 */ export const wrench = '\u{1F527}'
/* 📖 */ export const openBook = '\u{1F4D6}'
/* ❌ */ export const redCrossMark = '\u{274C}'
/* ❓  */ export const exclamation = '\u{2753}'
/* 👩‍💻 */ export const technologistWoman = '\u{1F469}\u{200D}\u{1F4BB}'
/* 🐓 */ export const rooster = '\u{1F413}'
/* ⏱ */ export const chronometer = '\u{23f1}'
/* 🔥 */ export const fire = '\u{1F525}'
/* ✨ */ export const sparkles = '\u{2728}'
/* 🎁 */ export const gift = '\u{1F381}'
/* ⚡ */ export const highVoltage = '\u{26a1}'
/* 💐 */ export const flowers = '\u{1F490}'
/* 💾 */ export const floppyDisk = '\u{1F4BE}'
/* ⚠️ */ export const warning = '\u{26A0}\u{FE0F}'
/* ⏰ */ export const alarmClock = '\u{23f0}'
/* 💸 */ export const wingedMoney = '\u{1f4b8}'
/* 💰 */ export const moneyBag = '\u{1F4B0}'
/* 💵 */ export const banknoteWithDollarSign = '\u{1F4B5}'
/* 🚩 */ export const triangularRedFlag = '\u{1F6A9}'
/* 🔎 */ export const magnifyingGlass = '\u{1F50E}'
/* 🌊 */ export const waterWave = '\u{1F30A}'
/* 💹 */ export const chartWithUpwardsTrendAndYenSign = '\u{1F4B9}'
/* 🏡 */ export const houseWithGarden = '\u{1F3E1}'
/* 🏦 */ export const bank = '\u{1F3E6}'
/* 🚘 */ export const oncomingAutomobile = '\u{1F698}'
/* 💳 */ export const creditCard = '\u{1F4B3}'
/* 🧭 */ export const compass = '\u{1F9ED}'
/* 🚨 */ export const policeCarsRevolvingLight = '\u{1F6A8}'
/* 🌟 */ export const glowingStar = '\u{1F31F}'
/* 🛒 */ export const shoppingTrolley = '\u{1F6D2}'
/* 🗺️ */ export const worldMap = '\u{1F5FA}\u{FE0F}'
/* 🏩 */ export const loveHotel = '\u{1F3E9}'
/* ❤️‍🔥 */ export const heartOnFire = '\u{2764}\u{FE0F}\u{200D}\u{1F525}'
