import useModals from 'components/App/ModalsManager/useModals'

import { FirstPayrollModal } from './index'

export function useFirstPayrollModal() {
  const modals = useModals()

  const handleClose = () => {
    modals.closeModal('firstPayrollModal')
  }

  const openFirstPayrollModal = () =>
    modals.openModal({
      id: 'firstPayrollModal',
      content: <FirstPayrollModal handleClose={handleClose} />,
      modalProps: {
        onCancel: handleClose,
        hideFooter: true,
        paperSx: {
          maxWidth: '56rem',
        },
        contentWrapperSx: {
          overflow: 'hidden',
        },
      },
    })

  return { openFirstPayrollModal }
}
