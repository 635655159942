import * as yup from 'yup'

export const initialValues = {
  name: '',
  email: '',
  role: '',
  active: true,
}

export const validationSchema = yup.object({
  name: yup
    .string()
    .required()
    .nullable()
    .min(3, 'El nombre debe tener al menos 3 caracteres.')
    .max(100, 'El nombre no puede superar los 100 caracteres.'),
  email: yup
    .string()
    .email('Debe ser un correo electrónico válido.')
    .required(),
  role: yup.string().nullable().required(),
  active: yup.boolean().nullable().required(),
})

export const userOptions = [
  {
    value: 'accountant',
    label: 'Contador',
  },
  {
    value: 'admin',
    label: 'Administrador',
  },
]
