import confetti from 'canvas-confetti'
import { useCallback } from 'react'

const useConfetti = () => {
  const throwConfetti = useCallback((options) => {
    confetti({ ...options, zIndex: 9999 })
  }, [])

  return { throwConfetti }
}

export default useConfetti
