import cardValidator from 'card-validator'
import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'

import SubscriptionAutomaticDebitFields from './AutomaticDebitFields'
import SubscriptionCreditCardFields from './CreditCardFields'
import SubscriptionPSEFields from './PSEFields'

yup.setLocale(yupLocaleES)

export const getPaymentFields = (
  isEditingPaymentMethod = false,
  newSubscriptionType
) => ({
  automatic_debit: {
    fields: (
      <SubscriptionAutomaticDebitFields
        isEditingPaymentMethod={isEditingPaymentMethod}
        newSubscriptionType={newSubscriptionType}
      />
    ),
  },
  credit_card: {
    fields: (
      <SubscriptionCreditCardFields
        isEditingPaymentMethod={isEditingPaymentMethod}
        newSubscriptionType={newSubscriptionType}
      />
    ),
  },
  pse: {
    fields: <SubscriptionPSEFields />,
  },
  bank_transfer: {
    fields: <SubscriptionPSEFields />,
  },
})

const creditCardFormSchema = yup.object({
  payer_name: yup.string().trim().nullable().required(),
  card_number: yup
    .number()
    .nullable()
    .required()
    .test(
      'test-number',
      'Número de tarjeta de crédito inválido',
      (value) => cardValidator.number(value).isValid
    ),
  expiration_date: yup
    .string()
    .nullable()
    .required()
    .test('test-expiration-year', 'Debe ser una fecha válida', (value) => {
      return cardValidator.expirationDate(value).isValid
    }),
  security_code: yup
    .string()
    .nullable()
    .required()
    .test('test-number', 'Código de seguridad inválido', function (value) {
      const type = cardValidator.number(this.parent.card_number).card?.type
      return cardValidator.cvv(`${value}`, type === 'american-express' ? 4 : 3)
        .isValid
    }),
})

const automaticDebitFormSchema = yup.object({
  document_type: yup.string().required(),
  id_number: yup.string().required(),
  bank: yup.object().required(),
  account_type: yup.string().required(),
  account_number: yup.string().required(),
})

const pseFormSchema = yup.object({
  client_type: yup.string().nullable().required(),
  bank: yup.object().nullable().required(),
})

export const getValidationSchema = (paymentMethod, isCurrentMethodPayment) => {
  if (isCurrentMethodPayment) return null

  switch (paymentMethod) {
    case 'automatic_debit':
      return yup.object({ automatic_debit_form: automaticDebitFormSchema })
    case 'pse':
      return yup.object({ pse_form: pseFormSchema })
    default:
      return yup.object({ credit_card_form: creditCardFormSchema })
  }
}

export const getInitialValues = (isCurrentMethodPayment = false) => ({
  is_current_payment_method: isCurrentMethodPayment,
  payment_method: 'credit_card',
})

export const paymentModality = {
  month: 'mensual',
  year: 'anual',
}

export const getInitialCopy = (paymentVariant, paymentMethods) => {
  if (paymentVariant === 'new_payment') {
    return 'Al completar este pago'
  }
  if (paymentVariant === 'payment_data_update') {
    return `Al ${paymentMethods?.length ? 'actualizar' : 'agregar'} tu método de pago`
  }
  if (paymentVariant === 'first_payment') {
    return 'Al pagar tu suscripción'
  }

  return 'Al completar esta acción'
}
