import * as yup from 'yup'

import { Box } from '@mui/material'

import { yupLocaleES } from 'utils/form'
import { cellphoneInput } from 'utils/regex'

import colombianPhoneNumber from 'assets/images/views/common/colombian_phone_number.png'

import FormField from '../FormField/Index'

yup.setLocale(yupLocaleES)

const getValidationSchema = (optional) =>
  yup
    .string()
    .nullable()
    .trim()
    .matches(
      cellphoneInput,
      'Debes ingresar un número celular válido para Colombia.'
    )
    [optional ? 'transform' : 'required'](
      optional ? (value) => (value === '' ? null : value) : undefined
    )

const CellphoneField = ({
  name,
  optional = false,
  label,
  placeholder,
  showColombianCountryCode,
  hideLabel,
  ...props
}) => {
  const validationSchema = getValidationSchema(optional)

  const validate = async (value) => {
    try {
      await validationSchema.validate(value)
      return undefined
    } catch (error) {
      return !value ? 'Debes ingresar este campo.' : error.errors[0]
    }
  }

  return (
    <FormField
      name={name || 'cellphone'}
      label={hideLabel ? null : label || 'Número celular'}
      placeholder={placeholder || 'Número celular'}
      variant="number"
      format="### ### ####"
      optional={optional}
      validate={validate}
      startAdornment={
        showColombianCountryCode ? (
          <Box
            component="img"
            src={colombianPhoneNumber}
            alt="Número de teléfono Colombiano"
            width={50}
            height={20}
          />
        ) : null
      }
      {...props}
    />
  )
}

export default CellphoneField
