import { useOutletContext } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import CompanyIdentificationNumberField from 'components/UI/Formik/CommonFields/CompanyIdentificationNumberField'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import FormField from 'components/UI/Formik/FormField/Index'

import { documentTypesOptions } from 'utils/company'

import {
  numberOfEmployeesOptions,
  onboardingPayrollFrequenciesOptions,
} from './helpers'

export function OnboardingCompanyData() {
  const { userName } = useOutletContext()

  return (
    <>
      <Typography
        variant="h4"
        sx={({ spacing }) => ({
          marginBottom: spacing(1),
          textAlign: 'center',
        })}
      >
        {userName
          ? `${userName}, cuéntanos sobre tu empresa`
          : 'Cuéntanos sobre tu empresa'}
      </Typography>
      <Typography
        variant="body1"
        color="white.dark"
        sx={({ spacing }) => ({
          marginBottom: spacing(2),
          textAlign: 'center',
        })}
      >
        Estos datos nos ayudarán a personalizar tu experiencia y optimizar la
        gestión de tu nómina.
      </Typography>
      <Box
        sx={({ spacing }) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: spacing(2),
          width: '100%',
        })}
      >
        <DocumentTypeField
          optional={false}
          label="Tipo de identificación de tu empresa"
          options={documentTypesOptions}
        />
        <CompanyIdentificationNumberField
          optional={false}
          label="Número de identificación"
          placeholder="Número de identificación de tu empresa"
        />
        <FormField
          variant="chip-group"
          optional={false}
          name="workers_number"
          label="Número de empleados"
          options={numberOfEmployeesOptions}
        />
        <FormField
          variant="chip-group"
          optional={false}
          name="payroll_frequency"
          label="Frecuencia de la nómina"
          options={onboardingPayrollFrequenciesOptions}
        />
      </Box>
    </>
  )
}
