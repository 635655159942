import useModals from 'components/App/ModalsManager/useModals'

import { NotWorkingChatModalContent } from './NotWorkingChatModal'

const useNotWorkingChatModal = () => {
  const modals = useModals()

  const handleClose = () => {
    modals.closeModal('notWorkingChatModal')
  }

  const openNotWorkingChatModal = () => {
    modals.openModal({
      id: 'notWorkingChatModal',
      content: <NotWorkingChatModalContent handleClose={handleClose} />,
      modalProps: {
        hideFooter: true,
        onCloseModal: handleClose,
        onCancel: handleClose,
        paperSx: {
          maxWidth: '55rem',
        },
        contentWrapperSx: {
          overflow: 'hidden',
        },
      },
    })
  }

  return { openNotWorkingChatModal }
}

export default useNotWorkingChatModal
