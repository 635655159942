import {
  Box,
  Button,
  ButtonBase,
  Typography,
  useMediaQuery,
} from '@mui/material'

import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'
import { Image } from 'components/UI/Image'
import OrganizationEmail from 'components/UI/OrganizationEmail'

import { cryEmoji } from 'utils/emojis'

export function NotWorkingChatModalContent({ handleClose }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          mobile: '1fr',
          tablet: '18rem 1fr',
        },
      }}
    >
      <ButtonBase
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          cursor: 'pointer',
          color: theme.palette.black.main,
          right: theme.spacing(-1),
          top: theme.spacing(0),
        })}
      >
        <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
      </ButtonBase>
      {!isMobile ? (
        <>
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.primary.light,
              width: '27.75rem',
              height: '30rem',
              borderRadius: '100%',
              position: 'absolute',
              left: theme.spacing(-18.5),
              top: theme.spacing(-8.75),
            })}
          />
          <Image
            src="bac678b3-19e9-4af7-494e-34fe6ec10d00"
            width={292}
            height={268}
            style={{
              zIndex: 1,
            }}
          />
        </>
      ) : null}
      <Box
        sx={(theme) => ({
          [theme.breakpoints.up('tablet')]: {
            marginLeft: theme.spacing(4),
          },
        })}
      >
        <Typography
          variant="h4"
          sx={(theme) => ({
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(3),
            textWrap: 'pretty',
          })}
        >
          Parece que tu navegador es demasiado cool y no pudimos cargar el chat{' '}
          <Emoji code={cryEmoji} />
        </Typography>
        <Typography
          color="black.dark"
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.spacing(3),
            gap: theme.spacing(1),
          })}
        >
          A veces, los navegadores o antivirus pueden bloquear ciertas
          funcionalidades, como nuestro chat. Intenta abrirlo desde otro
          navegador, para darte la mejor atención.
          <Box component="span">
            Si el error persiste, escríbenos a{' '}
            <OrganizationEmail color="primary.dark" />.
          </Box>
        </Typography>
        <Button
          size="large"
          onClick={handleClose}
          sx={(theme) => ({
            padding: theme.spacing(0, 10),
            [theme.breakpoints.down('tablet')]: {
              width: '100%',
            },
          })}
        >
          Cerrar
        </Button>
      </Box>
    </Box>
  )
}
