import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import Loading from 'components/UI/Loading/Loading'
import Modal from 'components/UI/Modal/Modal'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { useOnboardingFirstStepsCompleted } from 'utils/hooks/auth/onboarding'
import { useCompanyService } from 'utils/hooks/company/companyService'
import useCompanyWorkCenters from 'utils/hooks/company/decree2012Service'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import { useOnboardingIntroCompletedModal } from '../IntroCompletedModal/useIntroCompletedModal'
import {
  companyInfoItems,
  getCompanyValuesToSend,
  getInitialValues,
  getWorkCenterValuesToSend,
  validationSchema,
  workCenterItems,
} from './helpers'

export function BasicCompanyInfoModal({ handleClose, state }) {
  const queryClient = useQueryClient()
  const { handleError } = useErrorHandler()
  const [isEditing, setIsEditing] = useState(false)
  const { showSuccessMessage } = useNotifications()

  const { openOnboardingIntroCompletedModal } =
    useOnboardingIntroCompletedModal()

  const { isFirstPayrollIssued, isFirstWorkerCreated, isBasicDataCompleted } =
    useOnboardingFirstStepsCompleted()

  const companyId = getCompanyId()
  const companyQueryKey = ['companyInformation', companyId]
  const workCentersQueryKey = ['getWorkCenters', companyId]

  const {
    companyQuery: { data: company, isLoading: isCompanyQueryLoading },
    companyMutation,
  } = useCompanyService({ serviceParams: { queryKey: companyQueryKey } })

  const {
    companyWorkCentersQuery: {
      data: companyWorkCenters,
      isLoading: isCompanyWorkCentersQueryLoading,
    },
    companyWorkCentersMutation,
  } = useCompanyWorkCenters({
    serviceParams: { queryKey: workCentersQueryKey },
  })

  const firstCompanyWorkCenter = companyWorkCenters?.[0] ?? null

  const initialValues = getInitialValues({
    company,
    companyWorkCenter: firstCompanyWorkCenter ?? {},
  })

  const companyInfoMutation = (companyValues) => {
    return companyMutation.mutateAsync(
      {
        mutationMethod: 'PATCH',
        company: companyValues,
      },
      {
        onSuccess: () => {
          showSuccessMessage('Se ha actualizado la información de la empresa')
        },
      }
    )
  }

  const workCenterMutation = (companyWorkCenterValues) => {
    return companyWorkCentersMutation.mutateAsync(
      {
        mutationMethod: firstCompanyWorkCenter ? 'PUT' : 'POST',
        workCenterId: firstCompanyWorkCenter?.id ?? null,
        workCenterData: {
          work_center: companyWorkCenterValues,
        },
      },
      {
        onSuccess: () => {
          showSuccessMessage('Se ha actualizado el centro de trabajo')
        },
      }
    )
  }

  const handleSubmit = async (values) => {
    const companyValues = getCompanyValuesToSend(values, initialValues)
    const companyWorkCenterValues = getWorkCenterValuesToSend(
      values,
      initialValues
    )

    const companyInfoPromises = []

    if (Object.keys(companyValues).length) {
      companyInfoPromises.push(companyInfoMutation(companyValues))
    }

    if (Object.keys(companyWorkCenterValues).length) {
      companyInfoPromises.push(workCenterMutation(companyWorkCenterValues))
    }

    if (companyInfoPromises.length === 0) {
      return
    }

    try {
      await Promise.all(companyInfoPromises)
      if (companyInfoPromises.length && !isBasicDataCompleted) {
        await queryClient.invalidateQueries(companyQueryKey)

        Mixpanel.track(MIXPANEL_EVENTS.BASIC_COMPANY_INFO_COMPLETED, {
          user_id: getUserId(),
          company_id: getCompanyId(),
          user_role: getUserRole(),
        })

        if (isFirstWorkerCreated && isFirstPayrollIssued) {
          openOnboardingIntroCompletedModal()
        }
      }

      handleClose()
    } catch (error) {
      handleError(error)
    }
  }

  const areQueriesLoading =
    isCompanyWorkCentersQueryLoading || isCompanyQueryLoading

  const areMutationsLoading =
    companyWorkCentersMutation.isLoading || companyWorkCentersMutation.isLoading

  return (
    <Modal
      isLoading={areQueriesLoading}
      open={state.open}
      hideFooter
      paperSx={{
        maxWidth: '45.5rem',
      }}
      onCancel={handleClose}
      header="Revisa estos datos para ayudarte con la configuración de tu empresa"
    >
      {areQueriesLoading ? (
        <Loading />
      ) : (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
          })}
        >
          <Typography variant="body1" color="black.dark">
            La información de tu empresa será utilizada para ayudarte a generar
            la nómina más rápida que has visto. Estos datos siempre podrás
            editarlos desde la opción empresa.
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ errors }) => {
              return (
                <Form>
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      flexDirection: 'column',
                      gap: theme.spacing(2),
                    })}
                  >
                    <Typography
                      variant="h6"
                      color="primary.dark"
                      sx={(theme) => ({
                        margin: theme.spacing(1, 0, 1),
                      })}
                    >
                      Información de la empresa
                    </Typography>
                    <Box
                      sx={(theme) => ({
                        display: 'grid',
                        gap: theme.spacing(2),
                        gridTemplateColumns: '1fr',
                        [theme.breakpoints.up('sm')]: {
                          gridTemplateColumns: 'repeat(2, 1fr)',
                        },
                      })}
                    >
                      {companyInfoItems({ company, isEditing })}
                    </Box>
                    <Typography
                      variant="h6"
                      color="primary.dark"
                      sx={(theme) => ({
                        margin: theme.spacing(2, 0, 1),
                      })}
                    >
                      Centro de trabajo principal
                    </Typography>
                    <Box
                      sx={(theme) => ({
                        display: 'grid',
                        gap: theme.spacing(2),
                        gridTemplateColumns: '1fr',
                        [theme.breakpoints.up('sm')]: {
                          gridTemplateColumns: 'repeat(2, 1fr)',
                        },
                      })}
                    >
                      {workCenterItems({
                        workCenter: companyWorkCenters
                          ? companyWorkCenters[0]
                          : {},
                        isEditing,
                      })}
                    </Box>
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        marginTop: theme.spacing(4),
                        gap: theme.spacing(2),
                        [theme.breakpoints.down('tablet')]: {
                          flexDirection: 'column',
                        },
                      })}
                    >
                      {!isEditing ? (
                        <Button onClick={() => setIsEditing(true)}>
                          Editar
                        </Button>
                      ) : null}
                      {isEditing ? (
                        <Button
                          type="submit"
                          disabled={Object.keys(errors).length > 0}
                          loading={areMutationsLoading}
                        >
                          Guardar
                        </Button>
                      ) : null}
                      <Button variant="outlined" onClick={() => handleClose()}>
                        Cerrar
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )
            }}
          </Formik>
        </Box>
      )}
    </Modal>
  )
}
