import { useNavigate } from 'react-router-dom'

import { useMediaQuery } from '@mui/material'

import useEndFreeModals from 'components/App/Premium/useEndFreeModals'
import ButtonAddWorker from 'components/UI/Button/ButtonAddWorker'
import Table from 'components/UI/Table/Table'

import { getCompanyId } from 'utils/company'

import { getPeriodPayrolls } from 'services/payroll/periodService'

import { WORKER_INDEX } from 'config/routes'

import { usePeriod, usePeriodAPI } from '../helpers'
import ActionButton from './ActionButton'
import { getColumnsData } from './helpers'
import useTableActions from './useTableActions'

const PayrollDataTable = () => {
  const navigate = useNavigate()
  const {
    period: {
      editable,
      id: periodId,
      contracts_counter: contractsCounter = {},
    },
    payPreviousPayroll,
    locations,
    activeLocation,
    activeContract,
  } = usePeriod()
  const { setActiveLocation, setActiveContract } = usePeriodAPI()
  const companyId = getCompanyId()

  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))

  const fetchPayrolls = (pageIndex, search) => {
    return {
      queryKey: [
        'periodPayrolls',
        companyId,
        periodId,
        pageIndex,
        activeContract,
        search,
        activeLocation,
      ],
      queryFunction: () => {
        return getPeriodPayrolls({
          searchParams: {
            period_id: payPreviousPayroll ? periodId : undefined,
            page: pageIndex + 1,
            search,
            per_page: 30,
            contract_category: activeContract,
            location: activeLocation,
          },
        })
      },
    }
  }

  const handleAddWorkerManually = () => {
    navigate(WORKER_INDEX('?tab=active'), {
      state: {
        addWorkerFromPayPayroll: true,
      },
    })
  }

  const columnsData = getColumnsData(activeContract)

  const endFreePayrollModal = useEndFreeModals()

  const tableActions = useTableActions({
    contractsCounter,
    activeContract,
    setActiveContract,
    locations,
    activeLocation,
    setActiveLocation,
    editable,
    periodId,
    payPreviousPayroll,
    endFreePayrollModal,
  })

  return (
    <Table
      data={fetchPayrolls}
      columns={columnsData}
      options={{
        rowsSize: 30,
        customQueryFetch: 'payrolls',
        hiddenColumns: ['worker_id_number'],
        emptyState: {
          description:
            'Para liquidar tu primera nómina debes agregar al menos una persona.',
          title: 'No has agregado personas',
          callToAction: (
            <ButtonAddWorker
              handleAddWorkerManually={handleAddWorkerManually}
            />
          ),
        },
        customActionsWidth: '16.7rem',
        alignActionsCell: 'left',
        alignActionsHeader: 'center',
        version: {
          toolbar: 'v2',
        },
        searchProps: {
          sx: {
            maxWidth:
              isTabletUp && !payPreviousPayroll
                ? '10.625rem !important'
                : 'inherit',
          },
        },
      }}
      actions={tableActions}
      components={{
        ActionButton,
      }}
    />
  )
}

export default PayrollDataTable
